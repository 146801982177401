import React from "react";
import { graphql } from "gatsby";

import HowItWorks from "../components/HowItWorks";
import Layout from "../components/Layout";
import SEO from "../components/seo";

class How extends React.Component {
  render() {
    const { data } = this.props;
    const siteTitle = data.site.siteMetadata.title;

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO
          title="Loceye | Understanding Humans"
          keywords={[`loceye`, `eyetracking`, `marketing`, `ux`, "career"]}
        />
        <HowItWorks />
      </Layout>
    );
  }
}

export default How;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`;
